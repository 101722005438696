<template>
  <div>
    <div class="maintitle">家政公司列表</div>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="3">
        <el-button type="success" @click="addjiazheng">添加家政公司</el-button>
      </el-col>
    </el-row>
    <el-table :data="list" border>
      <el-table-column
          align="center"
          fixed
          prop="company"
          label="公司名称"
          width="150"
      ></el-table-column>
      <el-table-column
          align="center"
          fixed
          prop="contact"
          label="联系人"
          width="200"
      ></el-table-column>
      <el-table-column
          align="center"
          fixed
          prop="tel"
          label="手机号"
          width="150"
      ></el-table-column>
      <el-table-column
          align="center"
          fixed
          prop="credit_code"
          label="统一信用编码"
          width="200"
      ></el-table-column>
      <el-table-column
          align="center"
          prop="area_text"
          label="所属地区"
          min-width="200"
      ></el-table-column>
      <el-table-column
          sortable
          align="center"
          prop="logintime"
          label="最后登录时间"
          width="200"
      ></el-table-column>
      <el-table-column align="center" fixed="right" label="状态" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">正常</span>
          <span v-else>停用</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="450">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="mdfy(scope)">修改</el-button>
            <el-button size="small" @click="resetpassword(scope)">重置密码</el-button>
            <el-button size="small" @click="$router.push('/jiazheng/transaction/' + scope.row.uuid)">查看流水</el-button>
            <el-button size="small" @click="deluser(scope)">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="page"
        :page-sizes="pagesizes"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      list: [],
      page: 1, //当前页数
      total: 0, //信息总数（所有页）
      crntdistrict: "", //当前选择县/区
      district: [], //县/区列表
      crntscope: [], //当前修改家政公司
    };
  },
  computed: {},
  methods: {
    getlist: function () {
      let that = this;
      let param = {
        pagesize: this.pagesize,
        page: this.page,
      };
      this.$axios({
        url: this.HOST + "/jiameng/jiazheng/getList",
        method: "post",
        data: param,
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    mdfy(scope) {
      this.$router.push("/jiazheng/mdfy/" + scope.row.uuid);
    },
    addjiazheng() {
      this.$router.push("/jiazheng/add");
    },
    resetpassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/jiazheng/resetpassword",
        data: {id: uuid},
      }).then((res) => {
        if (res.data.status == 1) {
          this.$alert(
              scope.row.company + "的密码已经修改为" + res.data.info,
              "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info,
          });
        }
      });
    },
    deluser(scope) {
      this.$confirm("确定要删除" + scope.row.company + "吗？本操作不可恢复！！！", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          method: "post",
          url: this.HOST + "/jiameng/jiazheng/del",
          data: {
            id: scope.row.uuid
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.$message({
              type: "info",
              message: "操作成功"
            });
            this.list.splice(scope.$index, 1);
          } else {
            this.$message({
              type: "error",
              message: res.data.info
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    }
  },
  created: function () {
    this.getlist();
    this.getAreaList();
  },
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>